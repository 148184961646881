import { useBeforeUnload, useLocation } from '@remix-run/react'
import { diff } from 'deep-object-diff'
import { get as getIn, omit } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import { type FormContextValue } from 'remix-validated-form'
import { aIsFormLocked, useAtom } from '~/atoms'
import { toast } from '~/components/ui'
import { useLocalStorage } from '~/hooks'
import { preprocessFormData } from '~/utils'

export default function useFormHelpers<T = {}>(
  formId: string,
  formContext: FormContextValue,
  backupKey?: string
) {
  const location = useLocation()
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false)
  const [previousState, setPreviousState] = useState<T | null>(null)
  const [_, setIsFormLocked] = useAtom(aIsFormLocked)
  const [shouldCheck, setShouldCheck] = useState<boolean>(false)
  const backupRef = useRef<T | null>(null)
  const [backupData, __, clearBackupData] = useLocalStorage<T | {}>(
    backupKey || 'noop', // default key here because this isn't used outside of listing form currently
    {}
  )

  useEffect(() => {
    setTimeout(() => setShouldCheck(true), 1000)
  }, [])

  const maybeSaveBackup = useCallback(() => {
    try {
      if (backupKey) {
        if (backupRef.current) {
          // Using localstorage directly instead of hook which doesn't work here
          localStorage.setItem(backupKey, JSON.stringify(backupRef.current))
        } else {
          const item = localStorage.getItem(backupKey)
          if (item && item === '{}') {
            localStorage.removeItem(backupKey)
          }
        }
      }
    } catch (error) {
      console.error('Error handling draft data', error)
    }
  }, [])
  useEffect(() => () => maybeSaveBackup(), [])
  useBeforeUnload(maybeSaveBackup)

  useEffect(() => {
    const handleEnter = (event: any) => {
      if (
        event.keyCode === 13 &&
        (event.target.nodeName === 'INPUT' ||
          event.target.nodeName === 'SELECT')
      ) {
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', handleEnter)
    return () => document.removeEventListener('keydown', handleEnter)
  }, [])

  useEffect(() => {
    if (Object.keys(formContext.fieldErrors).length > 0) {
      console.error(formId, 'errors', formContext.fieldErrors)
      toast({
        title: 'Form Errors',
        description: 'Please check form for required fields and errors',
        variant: 'destructive',
      })
    }
  }, [formContext.fieldErrors, formId])

  useEffect(() => {
    if (formContext.isSubmitting) {
      return
    }

    // Default values get loaded after first render
    if (typeof getIn(formContext.defaultValues!, 'created') === 'undefined') {
      return
    } else if (!shouldCheck) {
      return
    }

    // location, sale and lease spaces _id, modified, created
    const ignoreKeys = [
      'organization[_id]',
      'organization[slug]',
      'organization[name]',
      'organization',
      'tid',
      '_id',
      'slug',
      'modified',
      'offMarketDate', // set to null in prepare listing, but shows as undefined on form
      'activeDate',
      'property.placeId',
      'property.country',
      'location',
    ]

    const data = preprocessFormData(formContext.getValues())
    if (previousState === null) {
      setPreviousState(data as T)
    } else {
      const changes = diff(
        omit({ ...previousState }, ignoreKeys),
        omit({ ...data }, ignoreKeys)
      )
      if (Object.keys(changes).length > 0) {
        console.info('Changes', changes)
        setIsFormChanged(true)
        setIsFormLocked(true)
        if (backupKey) {
          backupRef.current = data as T
        }
      }
    }
  }, [formContext, previousState, backupKey, shouldCheck])

  function handleOnBeforeUnload() {
    if (isFormChanged) {
      return 'Are you sure you want to leave this page? Changes you have made will not be saved.'
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (process.env.NODE_ENV === 'development' && import.meta.hot) {
      return
    }
    window.addEventListener('beforeunload', handleOnBeforeUnload)
  }, [isFormChanged])

  useEffect(() => {
    window.removeEventListener('beforeunload', handleOnBeforeUnload)
    setIsFormLocked(false)
  }, [location.pathname])

  return {
    isFormChanged,
    backupData,
    clearBackupData,
  }
}
