import { set as setIn } from 'lodash-es'

// Pulled from remix-validated-form repo
// https://github.com/airjp73/rvf/blob/b3dffbbba7609a8370fe2214939de01aa1d8b728/packages/remix-validated-form/src/validation/createValidator.ts

export class MultiValueMap<Key, Value> {
  private dict: Map<Key, Value[]> = new Map()

  add = (key: Key, value: Value) => {
    if (this.dict.has(key)) {
      this.dict.get(key)!.push(value)
    } else {
      this.dict.set(key, [value])
    }
  }

  delete = (key: Key) => {
    this.dict.delete(key)
  }

  remove = (key: Key, value: Value) => {
    if (!this.dict.has(key)) return
    const array = this.dict.get(key)!
    const index = array.indexOf(value)
    if (index !== -1) array.splice(index, 1)
    if (array.length === 0) this.dict.delete(key)
  }

  getAll = (key: Key): Value[] => {
    return this.dict.get(key) ?? []
  }

  entries = (): IterableIterator<[Key, Value[]]> => this.dict.entries()

  values = (): IterableIterator<Value[]> => this.dict.values()

  has = (key: Key): boolean => this.dict.has(key)
}

const objectFromPathEntries = (entries: [string, any][]) => {
  const map = new MultiValueMap<string, any>()
  entries.forEach(([key, value]) => map.add(key, value))
  return [...map.entries()].reduce(
    (acc, [key, value]) =>
      setIn(acc, key, value.length === 1 ? value[0] : value),
    {} as Record<string, any>
  )
}

export const preprocessFormData = (data: FormData): {} => {
  // A slightly janky way of determining if the data is a FormData object
  // since node doesn't really have FormData
  if ('entries' in data && typeof data.entries === 'function')
    return objectFromPathEntries([...data.entries()])
  return objectFromPathEntries(Object.entries(data))
}
